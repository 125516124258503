import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PDFViewer from './PDFViewer';
import './TabbedContainer.css';

// Define the categories
const categories: { [key: string]: { [key: string]: string } } = {
  allgemein: {
    patient: 'Patient',
    diagnose: 'Diagnose',
    einwilligung: 'Einwilligung',
    meldung_daten: 'Meldungsdaten',
    verlauf: 'Verlauf'
  },
  diagnostik: {
    molekulargenetik: 'Molekulargenetik',
    anamnese: 'Anamnese',
    begleiterkrankung: 'Begleiterkrankung',
    untersuchung: 'Untersuchung',
    labordiagnostik: 'Labordiagnostik',
    pathologiebefund: 'Pathologiebefund',
    stadium: 'Stadium'
  },
  therapie: {
    operative_therapie: 'Operative Therapie',
    strahlentherapie: 'Strahlentherapie',
    nuklearmed_therapie: 'Nuklearmedizinische Therapie',
    systemische_therapie: 'Systemische Therapie',
    stammzelltransplantation: 'Stammzelltransplantation',
    sonstige_therapie: 'Sonstige Therapie'
  },
  beratung: {
    ernaehrungsberatung: 'Ernährungsberatung',
    psychosozialdienst: 'Psychosozialdienst',
    screening_mangelernaehrung: 'Screening Mangelernährung',
    screening_psychoonkologie: 'Screening Psychoonkologie',
    psychoonkologie: 'Psychoonkologie',
    sozialdienst: 'Sozialdienst',
    genetische_beratung: 'Genetische Beratung'
  },
  studie: {
    studie: 'Studie'
  },
  konferenz: {
    mm_konferenz: 'MM Konferenz',
    fallbesprechung: 'Fallbesprechung',
    multidisziplinaere_besprechung: 'Multidisziplinäre Besprechung',
    tumorkonferenz: 'Tumorkonferenz',
    tumorkonferenz_evaluation: 'Tumorkonferenz Evaluation'
  }
};

const categoryDisplayNames: { [key: string]: string } = {
  allgemein: 'Allgemein',
  diagnostik: 'Diagnostik',
  therapie: 'Therapie',
  beratung: 'Beratung',
  studie: 'Studie',
  konferenz: 'Konferenz'
};

// Create Material UI theme
const theme = createTheme({
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

interface DocumentInfo {
  file_name: string;
  document_type?: string;
  date?: string;
  sections?: string[];
  [key: string]: any;
}

interface ProcessedData {
  sectionData: { [key: string]: any };
  sectionDocuments: { [key: string]: (string | DocumentInfo)[] };
}

interface TabbedContainerProps {
  processedData: ProcessedData | null;
  uploadedFiles: File[];
}

// Helper function to resolve schema references
function resolveSchemaRef(schema: any, rootSchema: any): any {
  if (!schema || !schema.$ref) return schema;
  
  const refPath = schema.$ref.split('/').slice(1);
  let resolvedSchema = rootSchema;
  for (const path of refPath) {
    resolvedSchema = resolvedSchema[path];
  }
  return resolvedSchema.$ref ? resolveSchemaRef(resolvedSchema, rootSchema) : resolvedSchema;
}

// Helper function to check if object is empty
function isEmptyObject(obj: any): boolean {
  if (!obj) return true;
  if (typeof obj !== 'object') return false;
  
  // Handle arrays
  if (Array.isArray(obj)) {
    if (obj.length === 0) return true;
    // For arrays, check if all items are empty
    return obj.every(item => isEmptyObject(item));
  }
  
  // Special handling for Wert-Quelle pairs
  if ('Wert' in obj) {
    const wertValue = obj.Wert;
    // If Wert is empty, an empty array, or an array of empty values, the object is considered empty
    if (Array.isArray(wertValue)) {
      return wertValue.length === 0 || wertValue.every(v => !v || v === '');
    }
    return !wertValue || wertValue === '' || wertValue === null || wertValue === undefined;
  }
  
  // For other objects, check if any non-metadata values exist
  const nonMetadataKeys = Object.keys(obj).filter(key => 
    key !== 'korrekt' && key !== 'Approved' && key !== 'Quelle' && key !== 'Comment'
  );

  // If there are no non-metadata keys, the object is empty
  if (nonMetadataKeys.length === 0) return true;

  // Check if all non-metadata values are empty
  return nonMetadataKeys.every(key => {
    const value = obj[key];
    if (value === null || value === undefined || value === '') return true;
    if (typeof value === 'object') return isEmptyObject(value);
    return false;
  });
}

// Function to check if schema is a Wert-Quelle pair or contains one
function containsWertQuellePair(schema: any, rootSchema: any): boolean {
  const resolvedSchema = resolveSchemaRef(schema, rootSchema);
  if (!resolvedSchema) return false;

  if (resolvedSchema.type === 'object' && resolvedSchema.properties) {
    // Check if this object directly has Wert-Quelle
    if ('Wert' in resolvedSchema.properties) return true;

    // Check nested properties
    return Object.values(resolvedSchema.properties).some(propSchema => 
      containsWertQuellePair(propSchema, rootSchema)
    );
  }

  return false;
}

// Function to check if data has a non-empty Wert
function hasNonEmptyWert(data: any): boolean {
  if (!data || typeof data !== 'object') return false;

  // Handle arrays
  if (Array.isArray(data)) {
    return data.some(item => hasNonEmptyWert(item));
  }

  // Check for Wert field
  if ('Wert' in data) {
    const wertValue = data.Wert;
    if (Array.isArray(wertValue)) {
      return wertValue.length > 0 && wertValue.some(v => v && v !== '');
    }
    return wertValue !== null && wertValue !== undefined && wertValue !== '';
  }

  // For objects without Wert, check all non-metadata fields
  const nonMetadataKeys = Object.keys(data).filter(key => 
    key !== 'korrekt' && key !== 'Approved' && key !== 'Quelle' && key !== 'Comment'
  );

  return nonMetadataKeys.some(key => {
    const value = data[key];
    if (Array.isArray(value)) {
      return value.length > 0 && value.some(v => hasNonEmptyWert(v));
    }
    if (typeof value === 'object') return hasNonEmptyWert(value);
    return value !== null && value !== undefined && value !== '';
  });
}

interface UiSchemaOptions {
  removable?: boolean;
  label?: boolean;
  antd?: {
    descriptionLocation?: string;
  };
}

interface UiSchemaItem {
  'ui:widget'?: string;
  'ui:options'?: UiSchemaOptions;
  [key: string]: any;
}

interface UiSchema {
  'ui:widget'?: string;
  'ui:options'?: UiSchemaOptions;
  'ui:submitButtonOptions'?: {
    submitText?: string;
    norender?: boolean;
  };
  items: {
    [key: string]: UiSchemaItem;
  };
  [key: string]: any;
}

// Function to generate UI Schema
function generateUiSchema(data: any, schema: any, rootSchema = schema): UiSchema {
  const resolvedSchema = resolveSchemaRef(schema, rootSchema);
  if (!resolvedSchema) return { items: {} };

  if (resolvedSchema.type === 'array' && resolvedSchema.items) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return { 'ui:widget': 'hidden', items: {} };
    }
    
    const arrayUiSchema: UiSchema = {
      items: {},
      'ui:options': { removable: false }
    };
    
    // Filter out array items without non-empty Wert
    const nonEmptyItems = data.filter(item => hasNonEmptyWert(item));
    if (nonEmptyItems.length === 0) {
      return { 'ui:widget': 'hidden', items: {} };
    }

    nonEmptyItems.forEach((item) => {
      const itemUiSchema = generateUiSchema(item, resolvedSchema.items, rootSchema);
      Object.keys(itemUiSchema).forEach(key => {
        if (!arrayUiSchema.items[key]) {
          arrayUiSchema.items[key] = {};
        }
        arrayUiSchema.items[key] = { 
          ...arrayUiSchema.items[key], 
          ...itemUiSchema[key] 
        };
      });
    });
    
    return arrayUiSchema;
  }

  let uiSchema: UiSchema = { items: {} };

  if (resolvedSchema.type === 'object' && resolvedSchema.properties) {
    // If this object only has metadata fields (korrekt, Quelle, Comment), hide it
    const hasOnlyMetadata = Object.keys(resolvedSchema.properties).every(key => 
      key === 'korrekt' || key === 'Approved' || key === 'Quelle' || key === 'Comment'
    );

    if (hasOnlyMetadata) {
      return { 'ui:widget': 'hidden', items: {} };
    }

    // Check if this object or any of its children contain Wert-Quelle pairs
    const hasWertQuelle = containsWertQuellePair(resolvedSchema, rootSchema);
    
    if (hasWertQuelle && !hasNonEmptyWert(data)) {
      return { 'ui:widget': 'hidden', items: {} };
    }

    Object.keys(resolvedSchema.properties).forEach(key => {
      const propertySchema = resolveSchemaRef(resolvedSchema.properties[key], rootSchema);
      const propertyData = data?.[key];

      // Skip metadata fields in UI schema
      if (key === 'korrekt' || key === 'Approved' || key === 'Quelle' || key === 'Comment') {
        return;
      }

      if (!propertyData || isEmptyObject(propertyData)) {
        uiSchema[key] = { 'ui:widget': 'hidden' };
      } else {
        const propertyUiSchema = generateUiSchema(propertyData, propertySchema, rootSchema);
        if (Object.keys(propertyUiSchema).length > 0 && propertyUiSchema['ui:widget'] !== 'hidden') {
          uiSchema[key] = propertyUiSchema;
        } else {
          uiSchema[key] = { 'ui:widget': 'hidden' };
        }
      }
    });

    // If all properties are hidden, hide the entire object
    const hasVisibleProperties = Object.keys(uiSchema).some(key => 
      key !== 'items' && uiSchema[key]['ui:widget'] !== 'hidden'
    );
    
    if (!hasVisibleProperties) {
      return { 'ui:widget': 'hidden', items: {} };
    }
  }

  return uiSchema;
}

// Add MemoizedForm component
const MemoizedForm = React.memo(Form);

export const TabbedContainer: React.FC<TabbedContainerProps> = ({ processedData, uploadedFiles }) => {
  const [activeMainCategory, setActiveMainCategory] = useState<string>('');
  const [activeSubCategory, setActiveSubCategory] = useState<string>('');
  const [schema, setSchema] = useState<RJSFSchema | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const formRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [uiSchema, setUiSchema] = useState<any>({});
  
  // Use ref for edited data
  const editedDataRef = useRef<ProcessedData | null>(processedData);
  const [, forceUpdate] = useState({});

  // Initialize editedData when processedData changes (only once at the start)
  useEffect(() => {
    if (processedData && !editedDataRef.current) {
      editedDataRef.current = processedData;
    }
  }, [processedData]);

  // Get current section key and form data
  const currentSectionKey = `${activeMainCategory}.${activeSubCategory}`;
  const formData = editedDataRef.current?.sectionData?.[currentSectionKey] || null;

  // Save scroll position before tab switch
  const handleTabSwitch = useCallback((newMainCategory: string, newSubCategory: string) => {
    if (formRef.current) {
      setScrollPosition(formRef.current.scrollTop);
    }
    setActiveMainCategory(newMainCategory);
    setActiveSubCategory(newSubCategory);
  }, []);

  // Restore scroll position after tab switch
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollTop = scrollPosition;
    }
  }, [activeMainCategory, activeSubCategory, scrollPosition]);

  // Helper function to check if a section has data
  const hasSectionData = useCallback((mainCategory: string, subCategory: string): boolean => {
    if (!editedDataRef.current?.sectionData) return false;
    const sectionKey = `${mainCategory}.${subCategory}`;
    
    // First check if the section exists in the data
    if (!(sectionKey in editedDataRef.current.sectionData)) {
      return false;
    }
    
    const data = editedDataRef.current.sectionData[sectionKey];
    if (!data) return false;
    
    const hasNonEmptyValues = (obj: any): boolean => {
      if (Array.isArray(obj)) {
        return obj.length > 0 && obj.some(item => 
          typeof item === 'object' ? hasNonEmptyValues(item) : Boolean(item)
        );
      }
      if (typeof obj === 'object' && obj !== null) {
        return Object.values(obj).some(value => 
          typeof value === 'object' ? hasNonEmptyValues(value) : Boolean(value)
        );
      }
      return Boolean(obj);
    };

    return hasNonEmptyValues(data);
  }, []);

  // Get available categories (those with data)
  const getAvailableCategories = useCallback(() => {
    return Object.keys(categories).filter(category => 
      Object.keys(categories[category]).some(subCategory => 
        hasSectionData(category, subCategory)
      )
    );
  }, [hasSectionData]);

  // Get available subcategories for the current main category
  const getAvailableSubCategories = useCallback((mainCategory: string) => {
    if (!mainCategory || !categories[mainCategory]) {
      return [];
    }
    return Object.entries(categories[mainCategory]).filter(([subCategory]) => 
      hasSectionData(mainCategory, subCategory)
    );
  }, [hasSectionData]);

  // Set initial active categories
  useEffect(() => {
    const availableCategories = getAvailableCategories();
    if (availableCategories.length > 0) {
      const firstCategory = availableCategories[0];
      const availableSubCategories = getAvailableSubCategories(firstCategory);
      
      if (availableSubCategories.length > 0) {
        setActiveMainCategory(firstCategory);
        setActiveSubCategory(availableSubCategories[0][0]);
      }
    }
  }, [processedData, getAvailableCategories, getAvailableSubCategories]);

  // Only try to load schema if we have active categories
  useEffect(() => {
    if (!activeMainCategory || !activeSubCategory) {
      return;
    }

    const loadSchema = async () => {
      try {
        const pascalCaseSubCategory = activeSubCategory
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        
        const schemaModule = await import(`../json_schemas/${activeMainCategory}/${pascalCaseSubCategory}RootModel.json`);
        setSchema(schemaModule.default);
        
        // Generate uiSchema once when schema is loaded
        const initialData = editedDataRef.current?.sectionData?.[currentSectionKey] || {};
        const generatedUiSchema = generateUiSchema(initialData, schemaModule.default);
        setUiSchema({
          ...generatedUiSchema,
          'ui:submitButtonOptions': {
            norender: true,
          }
        });
      } catch (error) {
        console.error(`Failed to load schema for ${activeMainCategory}/${activeSubCategory}:`, error);
        setSchema(null);
        setUiSchema({});
      }
    };

    loadSchema();
  }, [activeMainCategory, activeSubCategory, currentSectionKey]);

  // Modify handleChange to update the editedDataRef
  const handleChange = useCallback(({ formData: newFormData }: any) => {
    if (editedDataRef.current) {
      editedDataRef.current = {
        ...editedDataRef.current,
        sectionData: {
          ...editedDataRef.current.sectionData,
          [currentSectionKey]: newFormData
        }
      };
    }
  }, [currentSectionKey]);

  const handleError = (errors: any) => {
    console.log('Form validation errors:', errors);
  };

  const handleSubmit = ({ formData }: any) => {
    console.log('Form submitted:', formData);
  };

  // Modify handleSubmitAllData to use editedDataRef
  const handleSubmitAllData = async () => {
    try {
      const response = await fetch('https://ukr-testing.api.medmatic.ai/v1/send-json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'f6453837-ada3-43f3-8cbe-74653b9e3ade'
        },
        body: JSON.stringify({ formData: editedDataRef.current?.sectionData })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log("success", data);
      alert('Daten erfolgreich gesendet');
    } catch (error) {
      console.log("Senden fehlgeschlagen", error);
      alert('Senden fehlgeschlagen');
    }
  };

  // Custom object field template to add search buttons
  const ObjectFieldTemplate = React.memo((props: any) => {
    const hasQuelle = props.formData?.Quelle;
    
    const handleSearchClick = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (hasQuelle) {
        props.registry.setSearchText(props.formData.Quelle);
      }
    }, [hasQuelle, props]);

    // Calculate hierarchy level based on the number of dots in the id path
    const getHierarchyLevel = (id: string): number => {
      if (!id) return 0;
      return (id.match(/\./g) || []).length;
    };

    // Get title style based on hierarchy level
    const getTitleStyle = (id: string): React.CSSProperties => {
      const level = getHierarchyLevel(id);
      return {
        fontWeight: 'bold',
        fontSize: `${Math.max(1.2 - level * 0.1, 0.9)}rem`,
        marginBottom: '0.5rem',
        color: '#000000'
      };
    };
    
    return (
      <div className="object-field-container">
        {props.title && (
          <div className="object-field-header">
            <h3 style={getTitleStyle(props.idSchema.$id)}>{props.title}</h3>
            {hasQuelle && (
              <button
                type="button"
                className="search-button"
                onClick={handleSearchClick}
                title="Search in PDF"
              >
                🔍 Search
              </button>
            )}
          </div>
        )}
        <div className="object-field-properties" style={{ marginLeft: getHierarchyLevel(props.idSchema.$id) > 0 ? '1rem' : '0' }}>
          {props.properties.map((prop: any) => prop.content)}
        </div>
      </div>
    );
  });

  const currentDocuments = editedDataRef.current?.sectionDocuments?.[currentSectionKey] || [];
  const availableCategories = getAvailableCategories();
  const availableSubCategories = getAvailableSubCategories(activeMainCategory);

  // Add this function inside TabbedContainer component
  const getSourceText = (data: any): string | undefined => {
    if (!data) return undefined;
    
    // Helper function to search for Quelle field
    const findQuelle = (obj: any): string | undefined => {
      if (!obj || typeof obj !== 'object') return undefined;
      
      if ('Quelle' in obj) {
        const quelleValue = obj.Quelle;
        console.log('Original Quelle value:', quelleValue);
        // Clean up the Quelle value by removing everything up to and including "|"
        if (quelleValue && typeof quelleValue === 'string' && quelleValue.includes('|')) {
          const cleanedValue = quelleValue.split('|')[1].trim();
          console.log('Cleaned Quelle value:', cleanedValue);
          return cleanedValue;
        }
        return quelleValue;
      }
      
      // Search in nested objects and arrays
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          for (const item of obj[key]) {
            const result = findQuelle(item);
            if (result) return result;
          }
        } else if (typeof obj[key] === 'object') {
          const result = findQuelle(obj[key]);
          if (result) return result;
        }
      }
      
      return undefined;
    };
    
    const result = findQuelle(data);
    console.log('Final getSourceText result:', result);
    return result;
  };

  if (availableCategories.length === 0) {
    return (
      <Paper elevation={1} sx={{ p: 2, m: 2 }}>
        <Typography>Keine Daten verfügbar</Typography>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="tabbed-container">
        <div className="top-tabs">
          {availableCategories.map((category) => (
            <button
              key={category}
              className={`tab ${activeMainCategory === category ? 'active' : ''}`}
              onClick={() => {
                const firstAvailableSubCategory = getAvailableSubCategories(category)[0]?.[0];
                if (firstAvailableSubCategory) {
                  handleTabSwitch(category, firstAvailableSubCategory);
                }
              }}
            >
              {categoryDisplayNames[category]}
            </button>
          ))}
        </div>

        <div className="sub-tabs">
          {availableSubCategories.map(([key, displayName]) => (
            <button
              key={key}
              className={`tab ${activeSubCategory === key ? 'active' : ''}`}
              onClick={() => handleTabSwitch(activeMainCategory, key)}
            >
              {displayName}
            </button>
          ))}
        </div>

        <div className="content-area">
          <div className="form-section" ref={formRef}>
            {schema && (
              <MemoizedForm
                schema={schema}
                validator={validator}
                formData={formData}
                onChange={handleChange}
                onError={handleError}
                onSubmit={handleSubmit}
                uiSchema={uiSchema}
                templates={{ 
                  ObjectFieldTemplate: (props: any) => (
                    <ObjectFieldTemplate {...props} registry={{ ...props.registry, setSearchText }} />
                  ) 
                }}
              />
            )}
          </div>
          <div className="pdf-viewer-section">
            {currentDocuments.length > 0 && (
              <PDFViewer
                files={uploadedFiles}
                documentNames={currentDocuments.map(doc => 
                  typeof doc === 'string' ? doc : doc.file_name
                )}
                searchText={(() => {
                  const rawSearchText = searchText || getSourceText(formData);
                  if (rawSearchText && typeof rawSearchText === 'string' && rawSearchText.includes('|')) {
                    const cleanedText = rawSearchText.split('|')[1].trim();
                    console.log('Cleaned search text:', cleanedText);
                    return cleanedText;
                  }
                  return rawSearchText;
                })()}
              />
            )}
          </div>
        </div>
        <div className="submit-container">
          <button 
            className="submit-all-button"
            onClick={handleSubmitAllData}
          >
            Alle Daten senden
          </button>
        </div>
      </div>
    </ThemeProvider>
  );
}; 