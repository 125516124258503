import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PDFViewer.css';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

// Calculate similarity between two strings
function calculateSimilarity(str1: string, str2: string) {
  // Clean and normalize the strings
  str1 = str1.toLowerCase().trim();
  str2 = str2.toLowerCase().trim();
  
  // Extract words and numbers
  const getWordsAndNumbers = (text: string) => {
    const words = text.split(/[\s,.:]+/).filter(word => word.length > 0);
    const numbers = text.match(/\d+(?:\.\d+)?/g) || [];
    return { words, numbers: numbers as string[] };
  };
  
  const { words: words1, numbers: numbers1 } = getWordsAndNumbers(str1);
  const { words: words2, numbers: numbers2 } = getWordsAndNumbers(str2);
  
  // Count matching words
  const commonWords = words1.filter(word => 
    words2.includes(word)
  );
  
  // Count matching numbers (exact matches only)
  const commonNumbers = numbers1.filter(num => 
    numbers2.includes(num)
  );
  
  // Primary score based on number of matching words
  const numMatchingWords = commonWords.length;
  
  // Secondary score based on proportion of words that match
  const proportionScore = numMatchingWords / Math.max(words1.length, words2.length);
  
  // Number matching bonus (0.2 per matching number)
  const numberBonus = commonNumbers.length * 0.2;
  
  // Combine scores
  let similarity = (numMatchingWords * 0.1) + (proportionScore * 0.5) + numberBonus;
  
  // Small bonus for exact matches to break ties
  if (str1 === str2) {
    similarity += 0.1;
  }
  
  return similarity;
}

interface PDFViewerProps {
  files: File[];
  documentNames: string[];
  searchText?: string;
}

interface BestMatch {
  page: number;
  text: string;
  similarity: number;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ files, documentNames, searchText }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);
  const [bestMatches, setBestMatches] = useState<BestMatch[]>([]);
  const [scale, setScale] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth - 32; // subtract padding
        setScale(containerWidth / 595); // 595 is the default PDF width in points
      }
    };

    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => 
      prevPageNumber ? Math.min(Math.max(1, prevPageNumber + offset), numPages || 1) : 1
    );
  }

  const goToPrevPage = () => changePage(-1);
  const goToNextPage = () => changePage(1);

  const goToPrevFile = () => {
    setCurrentFileIndex(prev => Math.max(prev - 1, 0));
    setPageNumber(1);
  };

  const goToNextFile = () => {
    setCurrentFileIndex(prev => Math.min(prev + 1, files.length - 1));
    setPageNumber(1);
  };

  // Search functionality
  React.useEffect(() => {
    async function performSearch() {
      if (!searchText || !files[currentFileIndex]) return;

      try {
        const loadingTask = pdfjs.getDocument(URL.createObjectURL(files[currentFileIndex]));
        const pdf = await loadingTask.promise;
        const results: number[] = [];
        const matches: BestMatch[] = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const textItems = textContent.items as any[];
          
          for (const item of textItems) {
            if (!item.str.trim()) continue;
            
            const trimmedStr = item.str.trim();
            const isNumber = /^\d+(?:\.\d+)?$/.test(trimmedStr);
            
            const similarity = calculateSimilarity(trimmedStr, searchText);
            if (similarity > 0.1 && (trimmedStr.length > 1 || isNumber)) {
              if (!results.includes(i)) {
                results.push(i);
              }
              matches.push({
                page: i,
                text: trimmedStr,
                similarity: similarity
              });
            }
          }
        }
        
        matches.sort((a, b) => b.similarity - a.similarity);
        const bestMatches = matches.slice(0, 5);
        setBestMatches(bestMatches);
        
        if (bestMatches.length > 0) {
          setPageNumber(bestMatches[0].page);
        }
      } catch (error) {
        console.error('Error searching PDF:', error);
      }
    }

    performSearch();
  }, [searchText, currentFileIndex, files]);

  const textRenderer = useCallback(
    (textItem: { str: string; itemIndex?: number; transform?: number[] }) => {
      if (!searchText || !bestMatches.length) return textItem.str;
      
      const bestMatch = bestMatches[0];
      
      if (bestMatch.page === pageNumber && 
          bestMatch.text === textItem.str.trim()) {
        return `<mark class="pdf-highlight" data-highlight-text="${textItem.str}">${textItem.str}</mark>`;
      }
      
      return textItem.str;
    },
    [searchText, pageNumber, bestMatches]
  );

  // Handle scrolling to highlight after page render
  useEffect(() => {
    if (!searchText || !bestMatches.length || bestMatches[0].page !== pageNumber) return;

    const scrollToHighlight = () => {
      const highlight = document.querySelector('.pdf-highlight');
      if (highlight) {
        const container = containerRef.current;
        if (!container) return;

        // Get the container's scroll position and dimensions
        const containerRect = container.getBoundingClientRect();
        const highlightRect = highlight.getBoundingClientRect();

        // Calculate the scroll position to center the highlight
        const scrollTop = container.scrollTop + (highlightRect.top - containerRect.top) - (containerRect.height / 2) + (highlightRect.height / 2);

        container.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        });
      }
    };

    // Wait for the page and text layer to render
    const timeoutId = setTimeout(scrollToHighlight, 300);
    return () => clearTimeout(timeoutId);
  }, [searchText, bestMatches, pageNumber]);

  return (
    <div className="pdf-viewer">
      <div className="pdf-controls">
        <button onClick={goToPrevFile} disabled={currentFileIndex === 0}>
          Previous File
        </button>
        <button onClick={goToPrevPage} disabled={pageNumber <= 1}>
          Previous Page
        </button>
        <span>
          Page {pageNumber} of {numPages || '--'}
        </span>
        <button onClick={goToNextPage} disabled={pageNumber >= (numPages || 1)}>
          Next Page
        </button>
        <button onClick={goToNextFile} disabled={currentFileIndex >= files.length - 1}>
          Next File
        </button>
      </div>

      <div className="pdf-document" ref={containerRef}>
        <Document
          file={files[currentFileIndex]}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          loading={<div>Loading PDF...</div>}
        >
          <Page 
            pageNumber={pageNumber} 
            renderTextLayer={true}
            renderAnnotationLayer={true}
            customTextRenderer={textRenderer}
            loading={<div>Loading page...</div>}
            scale={scale}
          />
        </Document>
      </div>

      <div className="pdf-info">
        <p>Current file: {documentNames[currentFileIndex]}</p>
      </div>
    </div>
  );
};

export default PDFViewer; 