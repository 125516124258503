import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './FileUploadSection.css';

interface FileUploadSectionProps {
  onFileSelect: (files: File[]) => void;
  onProcessedData: (data: ProcessedData) => void;
  selectedFiles: File[];
  isLoading?: boolean;
  onStartProcessing: () => void;
  useMockData?: boolean;
}

interface ProcessedData {
  sectionData: { [key: string]: any };
  sectionDocuments: { [key: string]: string[] };
}

export const FileUploadSection: React.FC<FileUploadSectionProps> = ({ 
  onFileSelect, 
  selectedFiles,
  isLoading = false,
  onStartProcessing,
  useMockData = false
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Filter out any files with the same name to avoid duplicates
    const existingNames = new Set(selectedFiles.map(f => f.name));
    const newFiles = acceptedFiles.filter(file => !existingNames.has(file.name));
    onFileSelect([...selectedFiles, ...newFiles]);
  }, [onFileSelect, selectedFiles]);

  const removeFile = (indexToRemove: number) => {
    const newFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
    onFileSelect(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    multiple: true
  });

  return (
    <div className="file-upload-section">
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the PDF files here...</p>
        ) : (
          <div className="dropzone-content">
            <span className="plus-icon">+</span>
            <p>Drag and drop PDF files here, or click to select</p>
            {selectedFiles.length > 0 && (
              <div className="files-list">
                {selectedFiles.map((file, index) => (
                  <div key={file.name} className="file-item">
                    <span className="file-name">{file.name}</span>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFile(index);
                      }}
                      className="delete-button"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      
      <Button
        variant="contained"
        color="primary"
        onClick={onStartProcessing}
        disabled={selectedFiles.length === 0 || isLoading}
        className="process-button"
      >
        {isLoading ? (
          <>
            <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
            Verarbeitung...
          </>
        ) : (
          'Dokumentation Starten'
        )}
      </Button>
    </div>
  );
}; 