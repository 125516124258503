import React from 'react';
import ReactMarkdown from 'react-markdown';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import './SummarySection.css';

interface SummarySectionProps {
  summary: string | null;
  isLoading: boolean;
}

export const SummarySection: React.FC<SummarySectionProps> = ({ summary, isLoading }) => {
  console.log('SummarySection props:', { summary, isLoading });

  if (isLoading) {
    return (
      <Paper className="summary-section loading">
        <CircularProgress size={24} />
        <Typography>Generating summary...</Typography>
      </Paper>
    );
  }

  if (!summary) {
    return null;
  }

  return (
    <Paper className="summary-section">
      <div className="markdown-content">
        <ReactMarkdown>{summary}</ReactMarkdown>
      </div>
    </Paper>
  );
}; 