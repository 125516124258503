// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  padding: 1rem;
}

.upper-section {
  display: flex;
  gap: 1rem;
  height: 500px;
  min-height: 500px;
  width: 100%;
}

.upload-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.pdf-viewer {
  flex: 1 1;
  min-width: 400px;
  max-width: 800px;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
}

.pdf-viewer .MuiTabs-root {
  min-height: 48px;
  background-color: #fff;
}

.pdf-viewer .MuiTab-root {
  text-transform: none;
  min-height: 48px;
}

.no-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  font-size: 1.1rem;
}

/* Add styles for the form section */
.form-section {
  flex: 1 1;
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  overflow: auto;
} `, "",{"version":3,"sources":["webpack://./src/components/MainContent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;;AAEA,oCAAoC;AACpC;EACE,SAAO;EACP,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".main-content {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 1rem;\n  padding: 1rem;\n}\n\n.upper-section {\n  display: flex;\n  gap: 1rem;\n  height: 500px;\n  min-height: 500px;\n  width: 100%;\n}\n\n.upload-container {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n\n.pdf-viewer {\n  flex: 1;\n  min-width: 400px;\n  max-width: 800px;\n  background-color: #f5f5f5;\n  border-radius: 4px;\n  overflow: hidden;\n  border: 1px solid #e0e0e0;\n  display: flex;\n  flex-direction: column;\n}\n\n.pdf-viewer .MuiTabs-root {\n  min-height: 48px;\n  background-color: #fff;\n}\n\n.pdf-viewer .MuiTab-root {\n  text-transform: none;\n  min-height: 48px;\n}\n\n.no-pdf {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  color: #666;\n  font-size: 1.1rem;\n}\n\n/* Add styles for the form section */\n.form-section {\n  flex: 1;\n  padding: 1rem;\n  background-color: white;\n  border-radius: 4px;\n  border: 1px solid #e0e0e0;\n  overflow: auto;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
