import React, { useState } from 'react';
import { FileUploadSection } from './FileUploadSection';
import { TabbedContainer } from './TabbedContainer';
import { SummarySection } from './SummarySection';
import sampleData from '../MockData/sampleData.json';
import sampleSummary from '../MockData/sampleSummary.json';
import './MainContent.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface ProcessedData {
  sectionData: { [key: string]: any };
  sectionDocuments: { [key: string]: string[] };
}

export const MainContent: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [activeFileIndex, setActiveFileIndex] = useState<number>(0);
  const [processedData, setProcessedData] = useState<ProcessedData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState<string | null>(null);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);

  // Force mock data mode for testing
  // const useMockData = true; // Temporary override for testing
  const useMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';

  const handleProcessedData = (data: any) => {
    console.log('Processing API response:', data);
    
    if (!data || !data.result) {
      console.error('Invalid API response format:', data);
      return;
    }

    const { processed_sections, original_data } = data.result;
    
    const processedData: ProcessedData = {
      sectionData: processed_sections || {},
      sectionDocuments: original_data || {}
    };

    console.log('Processed data:', processedData);
    setProcessedData(processedData);
  };

  const handleFileSelect = (files: File[]) => {
    setSelectedFiles(files);
    if (activeFileIndex >= files.length) {
      setActiveFileIndex(Math.max(0, files.length - 1));
    }
  };

  const makeApiCall = async (url: string, formData: FormData) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'x-api-key': 'f6453837-ada3-43f3-8cbe-74653b9e3ade'
      },
      body: formData
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText
      });
      throw new Error(`HTTP error! status: ${response.status}\nResponse: ${errorText}`);
    }

    const data = await response.json();
    console.log('API Response:', {
      url,
      data
    });
    return data;
  };

  const handleStartProcessing = async () => {
    if (selectedFiles.length === 0) {
      alert('Please select at least one PDF file');
      return;
    }

    setIsLoading(true);
    setIsSummaryLoading(true);
    setSummary(null);
    setProcessedData(null);

    try {
      if (useMockData) {
        // Process summary first
        console.log('Using mock data instead of API call');
        await new Promise(resolve => setTimeout(resolve, 500)); // Simulate API delay
        setSummary(sampleSummary.data);
        setIsSummaryLoading(false);

        // Then process form data
        await new Promise(resolve => setTimeout(resolve, 500)); // Simulate API delay
        console.log('Loading mock data:', sampleData.data);
        handleProcessedData(sampleData.data);
      } else {
        // Process summary first
        const summaryFormData = new FormData();
        selectedFiles.forEach(file => {
          summaryFormData.append('upload_files', file);
        });

        console.log('Making summary API call with files:', selectedFiles.map(f => f.name));
        const summaryData = await makeApiCall(
          'https://ukr-testing.api.medmatic.ai/v1/summarize_text',
          summaryFormData
        );
        
        console.log('Summary API response:', summaryData);
        if (typeof summaryData === 'string') {
          setSummary(summaryData);
        } else if (summaryData?.result?.summary) {
          setSummary(summaryData.result.summary);
        } else {
          console.error('Invalid summary response format:', summaryData);
        }
        setIsSummaryLoading(false);

        // Then process the form data
        const processFormData = new FormData();
        selectedFiles.forEach(file => {
          processFormData.append('upload_files', file);
        });
        processFormData.append('use_image_processing', 'true');
        processFormData.append('use_dkg_schema', 'true');
        processFormData.append('use_structured_output', 'true');
        processFormData.append('use_cohesia_mappings', 'true');

        console.log('Making process API call with files:', selectedFiles.map(f => f.name));
        const processData = await makeApiCall(
          'https://ukr-testing.api.medmatic.ai/v1/process_multiple_files',
          processFormData
        );

        console.log('Process API response:', processData);
        handleProcessedData(processData);
      }
    } catch (error) {
      console.error('Error processing files:', error);
      if (error instanceof Error) {
        alert(`Error processing files: ${error.message}`);
      } else {
        alert('An unexpected error occurred while processing files.');
      }
    } finally {
      setIsLoading(false);
      setIsSummaryLoading(false);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveFileIndex(newValue);
  };

  return (
    <main className="main-content">
      <div className="upper-section">
        <div className="upload-container">
          <FileUploadSection 
            onFileSelect={handleFileSelect}
            onProcessedData={handleProcessedData}
            selectedFiles={selectedFiles}
            isLoading={isLoading}
            onStartProcessing={handleStartProcessing}
            useMockData={useMockData}
          />
        </div>
        <div className="pdf-viewer">
          {selectedFiles.length > 0 && (
            <>
              {selectedFiles.length > 1 && (
                <Tabs
                  value={activeFileIndex}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                  {selectedFiles.map((file, index) => (
                    <Tab key={index} label={file.name} />
                  ))}
                </Tabs>
              )}
              <iframe 
                src={URL.createObjectURL(selectedFiles[activeFileIndex]) + '#toolbar=0&navpanes=0&scrollbar=0'} 
                title="PDF Viewer"
                style={{ 
                  width: '100%',
                  height: selectedFiles.length > 1 ? 'calc(100% - 48px)' : '100%',
                  border: 'none'
                }}
              />
            </>
          )}
        </div>
      </div>

      <SummarySection 
        summary={summary}
        isLoading={isSummaryLoading}
      />

      {processedData && (
        <TabbedContainer 
          processedData={processedData} 
          uploadedFiles={selectedFiles}
        />
      )}
    </main>
  );
}; 