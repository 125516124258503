import React from 'react';
import './Header.css';

export const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo">Your Logo</div>
      <button className="logout-button">Logout</button>
    </header>
  );
} 